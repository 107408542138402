import React from 'react';
import logo from './navebar/logo.svg';

const Looder = () => {
    return (
        <div style={{ width: "100%" ,
            height: "100vh",
            background: "rgba(0, 0, 0, 0.8)",
            position: "absolute",
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }} >

            <img src={logo} width={'60%'} alt=""  className='animate_logo' />
           
        </div>
    );
}

export default Looder;
