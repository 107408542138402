import React from 'react';
import img from './1.png';
import Waysection from './section1/waysection';
import Prodect from './prodect/prodect';

const Home = () => {
    return (
        <div style={{ width: "100%" }}>
            <div style={{position:"relative"}} >


                <img src={img} alt="" width={"100%"} />

                <div  style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-60%)" }} >
                    <h1  style={{color:"white",fontWeight:"bolder",fontSize:"4.7vw"}} >Your Finest Option <br /> for Healthy Food</h1>
                    <h4  style={{color:"#cecece",fontSize:"2vw"}} >From climate emergency to food security,<br /> the way we farm is key  to solving some of <br /> the biggest issues facing us today.</h4>
                    <button className="btn btn-outline-light btn-lg m-4" style={{margin:"0",color:"#DABA3A",borderColor:"#DABA3A",fontWeight:"bolder"}}>Read more</button>
                </div>
            </div>

            <Waysection />
            <Prodect />

        </div>
    );
}

export default Home;
