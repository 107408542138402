import React from 'react';
import img from './1.png';
import { Container, Row, Col } from 'react-bootstrap'
import icon1 from './1.svg';
import icon2 from './2.svg';
import Api from '../../../api/Api.js';

const Contact = () => {


    const [data, setData] = React.useState({

        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const [disablebutton, setdisablebutton] = React.useState(false);

    const supmit = async (e) => {


        e.preventDefault();
        setdisablebutton(true)
        Api.post('/contact-us/', data).then((res) => {

            window.location.reload();

          
            console.log(res.data)

        }).catch((err) => {
            console.log(err)
            setdisablebutton(false)
        })

    }





    return (
        <div  >
            <img src={img} width={'100%'} alt="" />

            <Container className='my-5'>

                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "30px" }}>

                    <a href="mailto:agri_olivia@yahoo.com" style={{ textDecoration: "none" }}>
                        <div className='p-3' style={{ borderRadius: '30px', backgroundColor: "#DABA3A", display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                            <img src={icon1} width={50} alt="" />
                            <p className='m-0' style={{ color: "white" }}>agri_olivia@yahoo.com</p>
                        </div>
                    </a>


                    <a href="tel:+00 44 7957 559715" style={{ textDecoration: "none" }}>
                        <div className='p-3' style={{ borderRadius: '30px', backgroundColor: "#DABA3A", display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                            <img src={icon2} width={50} alt="" />
                            <p className='m-0' style={{ color: "white" }}>+00 44 7957 559715</p>
                        </div>
                    </a>


                </div>


            </Container>



            <Container className='my-5'>


                <h2 style={{ fontWeight: "bolder" }}>Please don't HesitateTo Contact Us</h2>

                <div className='d-flex justify-content-center'>
                    <form style={{ maxWidth: '650px' }} onSubmit={supmit} >

                        <Row className="my-3">
                            <Col>

                                <input type="text" required  className="form-control p-3" id="exampleFormControlInput1" placeholder="Your Name" onChange={(e) => setData({ ...data, name: e.target.value })} style={{ borderRadius: '20px',color:"#3A67DA" }} />
                            </Col>
                            <Col>

                                <input type="email" required  className="form-control p-3" id="exampleFormControlInput1" placeholder="Email Address" onChange={(e) => setData({ ...data, email: e.target.value })} style={{ borderRadius: '20px',color:"#3A67DA" }} />
                            </Col>
                        </Row>



                        <Row className="mb-3">
                            <Col>

                                <input type="tel" required className="form-control p-3" id="exampleFormControlInput1" placeholder="Phone "  onChange={(e) => setData({ ...data, phone: e.target.value })} style={{ borderRadius: '20px',color:"#3A67DA" }}/>
                            </Col>
                            <Col>

                                <input type="text" required className="form-control p-3" id="exampleFormControlInput1" placeholder="Subject"  onChange={(e) => setData({ ...data, subject: e.target.value })} style={{ borderRadius: '20px',color:"#3A67DA" }}/>
                            </Col>
                        </Row>

                       

                        <Row className="mb-3 px-md-2">


                            <textarea className="form-control p-3" required id="exampleFormControlTextarea1" rows="3" placeholder="Message"  onChange={(e) => setData({ ...data, message: e.target.value })} style={{ borderRadius: '20px',color:"#3A67DA" }}></textarea>

                        </Row>

                        <Row className="mb-3">

                            <div className="d-grid gap-2">
                                <input type='submit' disabled={disablebutton} className="btn  p-2" style={{ borderRadius: '20px',color:"#fff",background:"#DABA3A" }} />
                            </div>
                        </Row>


                    </form>
                </div>

            </Container>


        </div>
    );
}

export default Contact;
