import React from 'react';
import api from '../../../api/Api';
import { Col, Container, Pagination, Row } from 'react-bootstrap';
import Cart from './cart';

const Fertilizer = () => {


    const [data, setData] = React.useState([]);


    const [active, setActive] = React.useState(1);
   
    let items = [];

    for (let number = 1; number <= ((data.count / 12)+1); number++) {
        items.push(
            <Pagination.Item onClick={(e) => setActive(number)} key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }

    React.useEffect(() => {

        api.get(`/products/?page=${active}&page_size=12&search=Public`).then((res) => {
            setData(res.data)
            console.log(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [active])

    
   




    return (
        <div>

            <Container>

                <Row >

                    {
                        data.results ? data.results.map((item, index) => {

                            return (
                                <Cart index={index} item={item} />
                         ) }) : null
                    }


                </Row>


                <div className='d-flex justify-content-center'>
                        {
                            data.count  > 12 ? <Pagination className='mx-auto my-5' >{items}</Pagination> : <br />
                        }
                        
                    </div>
               

            </Container>







           
        </div>
    );
}

export default Fertilizer;
