
import './App.css';
import Home from './component/page/home/home';
// import bootstrap'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarpage from './component/navebar/Navbar_page'
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './component/footer/footer';
import About from './component/page/about/about';
import Contact from './component/page/contact/contact';
import Prodect from './component/page/prodect/prodect';
import Pecticides from './component/page/prodect/Pecticides';
import Seeds from './component/page/prodect/Seeds';
import Public from './component/page/prodect/Public';
import Fertilizer from './component/page/prodect/Fertilizer';
import All from './component/page/prodect/all';
import AOS from 'aos';

import Looder from './component/looder';
import Item from './component/page/prodect/item';




AOS.init();
AOS.init({

  duration: 4200


})

export const contx = React.createContext()

function App() {

  const [load, setload] = React.useState(true);

  React.useEffect(() => {

    setTimeout(() => {

      setload(false)

    }, 5000)



    window.scrollTo(0, 0);
  }, []);

  const [lang, setLang] = React.useState("en");


  return (<>

  
    <div style={{
      display: load ? "block" : "none"
    }}>
      <Looder  /> 
    </div>
     

 
    <div className="App" style={{
      display: load ?    "none" :"block"
    }}>
      <contx.Provider value={{ lang, setLang }}>

        <BrowserRouter>
          <Navbarpage />
          <Routes>


            <Route path="/" index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Prodect" element={<Prodect />} />
            <Route path="/Product/Pecticides" element={<Pecticides />} />
            <Route path="/Product/Seeds" element={<Seeds />} />
            <Route path="/Product/PublicHealth" element={<Public />} />
            <Route path="/Product/Fertilizer" element={<Fertilizer />} />
            <Route path="/Product/Allprodect" element={<All />} />
            <Route path="/Product/:id" element={<Item />} />





           


            {/* 
                
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/partners" element={<Partners />} />
                
 */}



          </Routes>

          <Footer />





        </BrowserRouter>



      </contx.Provider>
    </div>
    
     
    </>
  );
}

export default App;
