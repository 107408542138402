import React from 'react';
import bgbox from './bg.svg';
import { Row, Col, Container } from 'react-bootstrap'
import svg1 from './svg/1.svg';
import svg2 from './svg/2.svg';
import svg3 from './svg/3.svg';
import svg4 from './svg/4.svg';
import svg5 from './svg/5.svg';
import svg6 from './svg/6.svg';





const Waysection = () => {

    const img = [svg1, svg2, svg3, svg4, svg5, svg6];
    const data = [
        `Sustainable Agriculture`,
        `Organic Farming`,
        `Sustainable Crops`,
        `Learning Agriculture`,
        `Urban Agriculture Innovations`,
        `Community Farming Development`
    ]


    return (
        <div className='my-5'>

            <h1 style={{ fontWeight: 'bolder', color: "#3A67DA" }} >WHY CHOSE US ?</h1>

            <Container>

                <Row>

                    {
                        img.map((item, index) => {
                            return (
                                <Col  key={index}  className="mt-5 d-flex justify-content-center ">
                                    <div data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-offset="0" style={{ textAlign: 'center' ,width:'406px',height:'312px',backgroundImage: `url(${bgbox})`,backgroundRepeat: "no-repeat",backgroundSize: "cover",backgroundPosition: "center"}}>
                                        <img src={item} alt="" width={90} className='mt-5'/>
                                        <h5 className='mt-3 px-5' style={{color:"white"}}>{data[index]}</h5>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>




            </Container>








        </div>
    );
}

export default Waysection;
