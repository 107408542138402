import React from 'react';
import logo from './logo.svg';

const Footer = () => {
    return (
        <div className='m-0 p-3 d-flex justify-content-between' style={{ width: "100%" ,background:' rgba(66, 133, 60, 0.62)'}} >

            <img src={logo} width={200} alt="" />
            <p className='m-0 my-auto p-0 ' style={{verticalAlign:'middle',color:'#555'}}>Copyright @ 2024. All Rights Reserved. </p>
        </div>
    );
}

export default Footer;
