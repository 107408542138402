import React from 'react';
import img from './1.png';
import { Container } from 'react-bootstrap'


const About = () => {
    return (
        <div>
            <img src={img} width={'100%'} alt="" />

            <Container>



                <div className='my-5' style={{ maxWidth: "1050px", textAlign: "left" }}>

                    <p className='fs-5'>
                        we company olivia agri
                        we started work since 2017
                        we have products seeds and pecticides and public helth and
                        fertilizer
                        We aim to share interesting stories of promising innovations and the ways they are addressing complex and interconnected challenges from hunger and malnutrition, to climate action and vibrant rural livelihoods
                    </p>



                    <br />
                    <h3 style={{ fontWeight: "bolder", color: "#3A67DA" }}>Olivia - Nurturing Sustainable Agriculture</h3>

                    <p className='fs-5'>
                        Welcome to Olivia, where our commitment to the future of farming drives every aspect of our work. As a company, we stand at the intersection of knowledge, expertise, and resources, positioning ourselves to thrive amidst the ever-evolving conditions of the market. Our unwavering mission, "For the Future of Farming," serves as our guiding light, propelling us towards a vision of a sustainable farming industry—both ecologically and economically.

                    </p>




                    <br />
                    <h3 style={{ fontWeight: "bolder", color: "#3A67DA" }}>Our Strategy: Empowering Agriculture Amid Change</h3>

                    <p className='fs-5'>

                        In the face of dynamic market conditions, Olivia stands tall, equipped with the expertise and resources needed to fortify its position. Our strategy pivots on the core belief that sustainable agriculture is the key to a prosperous future. We are dedicated to navigating the challenges ahead, ensuring that our farmers and communities thrive in the long run.


                    </p>





                    <br />
                    <h3 style={{ fontWeight: "bolder", color: "#3A67DA" }}>Our Mission and Values: A Commitment to Excellence</h3>

                    <p className='fs-5'>
                        At Olivia, we embrace a set of values that define who we are and guide everything we do:

                    </p>

                    <Container>

                        <h3 style={{ fontWeight: "600", color: "#DABA3A" }}>Expertise at the Core:</h3>

                        <p className='fs-5'>
                            Collaboration is not just a buzzword for us. Our teams and partners actively engage with local communities, involving them in decision-making processes at every level. We believe that a thriving community is essential for the success of agriculture.
                        </p>



                        <br />
                        <h3 style={{ fontWeight: "600", color: "#DABA3A" }}>Community Collaboration:</h3>

                        <p className='fs-5'>
                            Collaboration is not just a buzzword for us. Our teams and partners actively engage with local communities, involving them in decision-making processes at every level. We believe that a thriving community is essential for the success of agriculture.
                        </p>


                        <br />
                        <h3 style={{ fontWeight: "600", color: "#DABA3A" }}>Creating Lasting Impact:</h3>

                        <p className='fs-5'>
                            Beyond immediate solutions, we are dedicated to delivering long-lasting change. Our focus extends beyond individual farmers to encompass their families and the environments in which they operate.
                        </p>





                        <br />
                        <h3 style={{ fontWeight: "600", color: "#DABA3A" }}>Innovation and Fearless Strategy:</h3>

                        <p className='fs-5'>
                            We are not afraid to challenge the status quo. Olivia is a beacon of innovation, constantly exploring and modeling new approaches. We fearlessly confront strategies that are failing, paving the way for a more resilient and adaptive agriculture sector.
                        </p>


                        <br /><br />



                    </Container>


                    <h3 style={{ fontWeight: "bolder", color: "#3A67DA" }}>Join Us on the Journey: Building a Sustainable Future</h3>

                    <p className='fs-5'>
                        As we navigate the complexities of the agricultural landscape, Olivia invites you to join us on this journey toward a sustainable and prosperous future. Our commitment to excellence, innovation, and community engagement sets us apart, and we look forward to shaping the future of farming together.
                    </p>



                </div>









            </Container>

            <h2 className='m-5' style={{
                // background: linear-gradient(91deg, #42853C -0.61%, #3A67DA 116.21%);
                // background-clip: text;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;

                background: 'linear-gradient(91deg, #42853C -0.61%, #3A67DA 116.21%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: "bolder",
                textAlign: "center",

            }}>Olivia-Cultivating Tomorrow, Today.</h2>


        </div>
    );
}

export default About;
