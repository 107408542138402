import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img1 from '../home/prodect/img/1.svg';
import img2 from '../home/prodect/img/2.svg';
import img3 from '../home/prodect/img/3.svg';
import img4 from '../home/prodect/img/4.svg';
import img from './1.png';
import {Link} from 'react-router-dom';
const Prodect = () => {
    return (<> 
    
    <img src={img} width={'100%'} alt="" />
    <div className='py-5 Productcomponent container-fluid' style={{ backgroundColor: "#D9D9D9" ,width:'100%'}}>

           

        <div className='my-5 py-2' style={{

            borderRadius: "0px 30px 30px 0px",
            background: "linear-gradient(97deg, #3A67DA -5%, #42853C 52.92%, #3A67DA 115.59%)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            maxWidth: "1300px",
        }} >

            <Row className='px-5 '>

                <Col md={6} className=" my-auto" >
                    <h1 style={{
                        // background: linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%);
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                        background: 'linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: "bolder",
                        textAlign: "left"
                    }}>Pecticides </h1>
                    <p style={{
                        textAlign: "left",
                        fontSize: "20px",
                        color: 'rgba(255, 255, 255, 0.70)'
                    }}>At [Olivia], we take pride in offering a comprehensive range of high-quality pesticides to support and enhance agricultural practices. Our commitment to innovation, sustainability, and effectiveness sets our pesticide products apart in the market</p>

                  <Link to={'/Product/Pecticides'} style={{ textDecoration: 'none' }}>  <button className="btn  btn-lg my-2" style={{ margin: "0", color: "#fff", borderColor: "#DABA3A", fontWeight: "bolder", backgroundColor: "#DABA3A", borderRadius: "25px", float: 'left' }}>Pecticides products</button> </Link>
                </Col>
                <Col md={6} className="my-3" >
                    <img src={img1} alt="" width={300} />
                </Col>

            </Row>

        </div>









        <div style={{
            display: "flex",
            justifyContent: "end",
        }}>
            <div className='my-5 py-2' style={{

                borderRadius: "30px 0px 0px 30px",
                background: "linear-gradient(97deg, #3A67DA -5%, #42853C 52.92%, #3A67DA 115.59%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                maxWidth: "1300px",


            }} >

                <Row className='px-5 ' style={{ direction: 'rtl' }} >

                    <Col md={6} className=" my-auto" >
                        <h1 style={{
                            // background: linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%);
                            // background-clip: text;
                            // -webkit-background-clip: text;
                            // -webkit-text-fill-color: transparent;
                            background: 'linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: "bolder",
                            textAlign: "left"
                        }}>Seeds  </h1>
                        <p style={{
                            textAlign: "left",
                            fontSize: "20px",
                            color: 'rgba(255, 255, 255, 0.70)'
                        }}>Welcome to our premium selection of seeds, where innovation meets nature to cultivate a future of abundance. At [Olivia], we take pride in offering a diverse range of high-quality seeds that empower farmers, gardeners, and enthusiasts alike.</p>

                       <Link to={'/Product/Seeds'} style={{ textDecoration: 'none' }}> <button className="btn  btn-lg my-2" style={{ margin: "0", color: "#fff", borderColor: "#DABA3A", fontWeight: "bolder", backgroundColor: "#DABA3A", borderRadius: "25px", float: 'left' }}>Seeds products</button> </Link>
                    </Col>
                    <Col md={6} className="my-3" >
                        <img src={img2} alt="" width={300} />
                    </Col>

                </Row>

            </div>
        </div>









        <div className='my-5 py-2' style={{

            borderRadius: "0px 30px 30px 0px",
            background: "linear-gradient(97deg, #3A67DA -5%, #42853C 52.92%, #3A67DA 115.59%)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            maxWidth: "1300px",
        }} >

            <Row className='px-5 '>

                <Col md={6} className=" my-auto" >
                    <h1 style={{
                        // background: linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%);
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                        background: 'linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: "bolder",
                        textAlign: "left"
                    }}>Fertilizer  </h1>
                    <p style={{
                        textAlign: "left",
                        fontSize: "20px",
                        color: 'rgba(255, 255, 255, 0.70)'
                    }}>At [Olivia], we understand the importance of nurturing healthy crops that yield abundant harvests. Our range of premium fertilizers is specially formulated to provide essential nutrients that promote robust plant growth, increased yields, and overall crop health.</p>

                   <Link to={'/Product/Fertilizer'} style={{ textDecoration: 'none' }}>  <button className="btn  btn-lg my-2" style={{ margin: "0", color: "#fff", borderColor: "#DABA3A", fontWeight: "bolder", backgroundColor: "#DABA3A", borderRadius: "25px", float: 'left' }}>Fertilizer products</button> </Link>
                </Col>
                <Col md={6} className="my-3" >
                    <img src={img3} alt="" width={300} />
                </Col>

            </Row>

        </div>









        <div style={{
            display: "flex",
            justifyContent: "end",
        }}>
            <div className='my-5 py-2' style={{

                borderRadius: "30px 0px 0px 30px",
                background: "linear-gradient(97deg, #3A67DA -5%, #42853C 52.92%, #3A67DA 115.59%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                maxWidth: "1300px",


            }} >

                <Row className='px-5 ' style={{ direction: 'rtl' }} >

                    <Col md={6} className=" my-auto" >
                        <h1 style={{
                            // background: linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%);
                            // background-clip: text;
                            // -webkit-background-clip: text;
                            // -webkit-text-fill-color: transparent;
                            background: 'linear-gradient(107deg, #FAFAFA 1.14%, #EBD376 93.52%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: "bolder",
                            textAlign: "left"
                        }}>Public health </h1>
                        <p style={{
                            textAlign: "left",
                            fontSize: "20px",
                            color: 'rgba(255, 255, 255, 0.70)'
                        }}>Our range of public health products is designed to address contemporary challenges, promoting healthier communities and contributing to the overall well-being of individuals. With a commitment to excellence and a focus on preventative measures, our products aim to create a lasting impact on public health outcomes.</p>

                       <Link to={'/Product/PublicHealth'} style={{ textDecoration: 'none' }}>  <button className="btn  btn-lg my-2" style={{ margin: "0", color: "#fff", borderColor: "#DABA3A", fontWeight: "bolder", backgroundColor: "#DABA3A", borderRadius: "25px", float: 'left' }}>Public health products</button> </Link>
                    </Col>
                    <Col md={6} className="my-3" >
                        <img src={img4} alt="" width={300} />
                    </Col>

                </Row>

            </div>

                   
        </div>

          <Link to={'/Product/Allprodect'} style={{ textDecoration: 'none' }}>   <button className="btn  btn-lg my-2" style={{ margin: "0", color: "#fff", borderColor: "#DABA3A", fontWeight: "bolder", backgroundColor: "#DABA3A", borderRadius: "25px",  }}>view all product</button> </Link>




    </div>
        </>
    );
}

export default Prodect;
