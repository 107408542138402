import React, { useEffect } from 'react';
import Api from '../../../api/Api';

const Categ = (props) => {

    const [data, setData] = React.useState([]);
    useEffect(() => {
        Api.get(`/categories/${props.id}/`).then((res) => {
                
            setData(res.data)
            console.log(res.data)
            
        }).catch((err) => {
            console.log(err)
        })
    }, [props.id] )
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ width: "100%", height: "70px", backgroundColor: "#42853C" ,color:'#F0F0F0',fontWeight:'bolder',fontSize:'24px' }} >{ data.name}</div>
    );
}

export default Categ;
