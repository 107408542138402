import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Api from '../../../api/Api';
import { Col, Container, Row } from 'react-bootstrap';
import Categ from './categ';

const Item = () => {
    const { id } = useParams();
    const [item, setItem] = React.useState({});

    useEffect(() => {
        Api.get(`/products/${id}`).then((res) => {
            setItem(res.data)
            console.log(res.data)
           
        }).catch((err) => {
            console.log(err)
        })
    },[id])
    return (
        <div style={{
            backgroundColor: "#F8F9FA",
        }}>
            <Categ id={item.category} />
            
            <div>
                <Container>
                    <Row>
                        <Col md={6} >

                            <div className='m-3 my-5' style={{ textAlign: "left" }}>
                                <h2 style={{ fontWeight: "bolder",color:"#3A67DA"}}>{item.name}</h2>

                                <p  style={{ textAlign: "left" ,color:'#555' ,fontSize:'15px'}}>{item.description}</p>
                            </div>

                        </Col>

                        <Col md={6} >
                            <img src={item.image} width={'100%'} style={{
                                borderRadius: "20px",
                               position:"sticky",
                               top:"100px",
                              
                            }} className='my-5' alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Item;
