import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import bimg from './1.svg'
import logo from './logo.svg'
import { NavLink } from 'react-router-dom';

function BasicExample() {
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" className="bg-body-tertiary m-0 p-0">
      <Container fluid className='m-0 p-0' >
        <Navbar.Brand href="#home" style={{
          backgroundImage: `url(${bimg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right",
          width: "300px",
          height: "70px",
          margin: "0",
          padding: "0",
          position: "relative",
        }}>

          <img
            alt=""
            src={logo}
            width="170"
            height="70"

            className="d-inline-block align-top"
          />

        </Navbar.Brand>





        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">


          <Nav className="mx-auto ">
            <NavLink style={{ textDecoration: 'none',fontSize:'larger',fontWeight:'bold', color: '#3A67DA', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/">Home</NavLink>
            <NavLink style={{ textDecoration: 'none',fontSize:'larger',fontWeight:'bold', color: '#3A67DA', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/about">About Us</NavLink>
            <NavLink style={{ textDecoration: 'none',fontSize:'larger',fontWeight:'bold', color: '#3A67DA', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/Prodect">Product</NavLink>
            <NavLink style={{ textDecoration: 'none',fontSize:'larger',fontWeight:'bold', color: '#3A67DA', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/contact">Contact Us</NavLink>



          </Nav>


        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;