import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Cart = (props) => {
    const index = props.index;
    const item = props.item;

    const cattext = (text) => {
        let temp = text.substring(0, 125)
        return temp
    }
    return (
        <Col key={index} className="mt-5 d-flex justify-content-center ">
            <Link style={{ textDecoration: "none" }} to={`/Product/${item.id}`}>

                <div className="hover-effect" style={{
                    // border-radius: 20px;
                    // border: 1px solid rgba(0, 0, 0, 0.24);
                    // background: #FFF;
                    // box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.07);
                    borderRadius: "20px",
                    border: "1px solid rgba(0, 0, 0, 0.24)",
                    background: "#FFF",
                    boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.07)",
                    padding: "0",
                    margin: "0",
                    width: "302px ",

                    

                }}>

                    <img src={item.image} width={'100%'} alt="" />
                    <h4 className='m-2 mb-1' style={{ fontWeight: "bold", color: '#3A67DA', textAlign: "left" }}>{item.name}</h4>
                    <p className='px-2 mt-0 pb-0' style={{ textAlign: "left", WebkitInitialLetter: "1", fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}>{cattext(item.description)}</p>
                    <span className='d-flex justify-content-start pb-3 px-2 pt-0'><button className="btn btn-outline-light btn-sm " style={{ margin: "0", color: "#DABA3A", border: "none", fontWeight: "bolder",borderRadius:"10px" }}>{'Read more -->'}</button>
                    </span>
                </div></Link>
        </Col>
    );
}

export default Cart;
